<script setup lang="ts">
// import { defaultStore } from "@/store";
import { type IUniTabProp } from "./HeaderBar.vue";
// import { useSessionStore } from "@/store/session";
import { ModalStore } from "@/store/modalStore";
import { useRouter } from "vue-router";
import { CompanyStore } from "@/store/companyStore";
import { UserStore } from "@/store/userStore";
import { openCustomToast } from "@/assets/js/helpers";

const userStore = UserStore();
const modalStore = ModalStore();

const companyStore = CompanyStore();

defineProps<{
  navList: IUniTabProp[];
}>();

const emit = defineEmits(["click-menu-item", "close"]);
// const defaultStore = defaultStore();
// const tokenStore = useSessionStore();
const router = useRouter();
const isLoggedIn = computed(() => true); // tokenStore.isLoggedIn
const hasLangSwitcher = computed(() => true);

async function onClickLogin() {
  emit("close");
  //   await defaultStore.onClickLoginPage();
}
async function onClickRegister() {
  emit("close");
  void router.push({
    name: "RegisterPage",
  });
}

function onClickAvatar() {
  emit("close");
  //   void defaultStore.goToProfilePage();
}
// profile
function editProfileModal() {
  const modal: any = {
    title: "Profile",
    component: "editProfileModal",
  };
  modalStore.setModalData(modal);
}
async function resetPassword() {
  const modal: any = {
    title: "Reset password",
    component: "resetPasswordModal",
  };
  setTimeout(() => {
    modalStore.setModalData(modal);
  }, 300);
}
function twoFA() {
  openCustomToast("Not currently supported", "error");
  // const modal: any = {
  //   title: "Profile",
  //   component: "editProfileModal",
  // };
  // modalStore.setModalData(modal);
  // open.value = false;
}
const isPage = computed(() => {
  return (pages: string[]) => {
    if (pages.includes(String(router.currentRoute.value.name))) {
      return true;
    } else {
      return false;
    }
  };
});
</script>

<template>
  <div class="header-mobile">
    <div class="action">
      <div class="company-class" style="margin-bottom: 30px">
        <companyLogo :company="companyStore.activeCompany" />
        <company-dropdown style="margin-top: 3px" />
      </div>
    </div>
    <div class="menu">
      <div
        v-for="navItem in navList"
        :key="navItem.name"
        class="item"
        :class="{ active: isPage(navItem.activeIf) }"
        @click="emit('click-menu-item', navItem)"
      >
        {{ navItem.label }}
      </div>
    </div>
    <div class="menu" style="margin-top: 30px">
      <span class="item" @click="editProfileModal">My Profile</span>
      <span class="item" @click="resetPassword">Reset password</span>
      <span class="item" @click="twoFA">Setup 2FA</span>

      <span class="logout" @click="userStore.logout()">
        Logout
        <!-- <img  src="@/assets/icons/logout.svg"/> -->
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.3655 13.1199H14.076C13.9879 13.1199 13.9054 13.1592 13.8503 13.2285C13.7219 13.3877 13.5844 13.5412 13.4395 13.6873C12.8468 14.293 12.1447 14.7757 11.3722 15.1087C10.5718 15.4538 9.71149 15.6309 8.84263 15.6293C7.96399 15.6293 7.11286 15.4532 6.31309 15.1087C5.54052 14.7757 4.83848 14.293 4.2458 13.6873C3.65205 13.0836 3.17862 12.3682 2.85171 11.5805C2.51236 10.764 2.34176 9.89694 2.34176 8.99991C2.34176 8.10289 2.51419 7.23583 2.85171 6.41933C3.17822 5.63092 3.64781 4.92117 4.2458 4.31254C4.84379 3.70391 5.539 3.2245 6.31309 2.89116C7.11286 2.54658 7.96399 2.37055 8.84263 2.37055C9.72127 2.37055 10.5724 2.54471 11.3722 2.89116C12.1463 3.2245 12.8415 3.70391 13.4395 4.31254C13.5844 4.46048 13.7201 4.61404 13.8503 4.77135C13.9054 4.84064 13.9898 4.87997 14.076 4.87997H15.3655C15.4811 4.87997 15.5526 4.74888 15.4884 4.64963C14.0815 2.41736 11.6198 0.939803 8.82245 0.947294C4.4274 0.95853 0.90365 4.60094 0.947674 9.08231C0.991698 13.4925 4.50994 17.0525 8.84263 17.0525C11.6326 17.0525 14.0833 15.5768 15.4884 13.3502C15.5508 13.2509 15.4811 13.1199 15.3655 13.1199ZM16.9962 8.88193L14.3933 6.78451C14.2961 6.70585 14.1548 6.77701 14.1548 6.90249V8.32574H8.39505C8.31434 8.32574 8.24831 8.39316 8.24831 8.47555V9.52427C8.24831 9.60667 8.31434 9.67408 8.39505 9.67408H14.1548V11.0973C14.1548 11.2228 14.2979 11.294 14.3933 11.2153L16.9962 9.11789C17.0138 9.10388 17.0279 9.08597 17.0377 9.06553C17.0475 9.04509 17.0525 9.02265 17.0525 8.99991C17.0525 8.97717 17.0475 8.95473 17.0377 8.93429C17.0279 8.91385 17.0138 8.89595 16.9962 8.88193Z"
            fill="#D0DCF3"
          />
        </svg>
      </span>
    </div>

    <!-- <button class="alt" @click="onClickRegister">
          <span>{{ $t("app.auth.register") }}</span>
        </button>
        <button @click="onClickLogin">
          <span>{{ $t("app.auth.login") }}</span>
        </button> -->
    <div class="action">
      <div v-if="isLoggedIn" class="avatar-section">
        <!-- <app-avatar @click="onClickAvatar"></app-avatar> -->

        <div class="upper">
          <avatar-image v-if="userStore.getUser" :user="userStore.getUser" />
          <div class="name-text">
            {{ userStore.getUser?.name }} {{ userStore.getUser?.lastName }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.header-mobile {
  max-width: 100vw;
  height: calc(100vh - 51px) !important;
  overflow-x: auto;
  // min-height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: fixed;
  inset: 0;
  top: $nav-top-bar;
  background-color: var(--color-background);
  color: var(--color-text);
  padding: 20px;
  z-index: 100;
  font-size: 20px;
  .active {
    color: var(--vt-c-active);
    // border-bottom: 2px solid var(--vt-c-active);
  }

  .company-class {
    display: flex;
  }
  .menu {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;

    .item {
      cursor: pointer;

      &:hover {
        filter: brightness(1.5);
      }
    }
  }

  .action {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    // align-items: initial;
    margin-top: 30px;

    button {
      width: 200px;
    }
  }
  .action:last-of-type {
    margin-top: auto;
    margin-bottom: 60px;
  }
  .upper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;

    .name-text {
      font-weight: 500;
      font-size: 18px;
      line-height: 20px;
      margin-left: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 205px;
    }
  }

  span.logout {
    // width: 100%;
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    // color: var(--vt-c-error);
    // display: inline-block;
    margin-top: 30px;
    svg > path {
      fill: var(--color-text-modal);
    }
  }

  span.logout:hover {
    // color: #8f1a30;

    transition: 0.5s;
    color: var(--vt-c-active);
    cursor: pointer;
    // img{
    //   filter: invert(0.6) sepia(1) saturate(5) hue-rotate(175deg);
    // }
    svg > path {
      transition: 0.5s;
      fill: var(--vt-c-active);
    }
  }
}
</style>
