<template>
  <header class="nav-wrapper">
    <div class="helper"></div>

    <div class="wrapper">
      <div class="logo-wrapper">
        <img
          src="../assets/images/OmniTaskerLogo.png"
          alt="logo image"
          style="cursor: pointer"
          @click="navigate('MyTasks')"
        />
        <div class="flex-class">
          <companyLogo :company="companyStore.activeCompany" />
          <company-dropdown style="margin-top: 3px" />
        </div>
      </div>
      <nav>
        <span
          :class="{
            active: isPage(['MyTasks', 'MyTask', 'MyTaskSection']),
          }"
          tabindex="0"
          @keyup.enter="navigate('MyTasks')"
          @click="navigate('MyTasks')"
        >
          My tasks
        </span>
        <span
          :class="{
            active: isPage([
              'MyProjectsList',
              'MyProjectTasks',
              'MyProjectTask',
              'MyProjectTaskSection',
            ]),
          }"
          tabindex="0"
          @keyup.enter="navigate('MyProjectsList')"
          @click="navigate('MyProjectsList')"
        >
          Projects
        </span>
        <span
          :class="{ active: isPage(['MyHours']) }"
          tabindex="0"
          @keyup.enter="navigate('MyHours')"
          @click="navigate('MyHours')"
        >
          Timesheets
        </span>
        <span
          :class="{ active: isPage(['MySettings']) }"
          tabindex="0"
          @keyup.enter="navigate('MySettings')"
          @click="navigate('MySettings')"
        >
          Settings
        </span>
      </nav>
      <div class="profile-wrapper">
        <profile-dropdown />
      </div>
    </div>
    <div class="helper"></div>
  </header>
</template>
<script setup lang="ts">
import { useRouter } from "vue-router";
import { ProjectStore } from "../store/projectStore";
import { TasksStore } from "../store/tasksStore";
import { CompanyStore } from "../store/companyStore";

const companyStore = CompanyStore();
const projectStore = ProjectStore();
const tasksStore = TasksStore();
const router = useRouter();
const currentRoute = useRoute();

function navigate(route: string): void {
  if (route === "MyTasks") {
    if (tasksStore.getSelectedTaskId && tasksStore.getSelectedTaskSection) {
      router.push({
        name: "MyTaskSection",
        params: {
          taskId: tasksStore.getSelectedTaskId,
          section: tasksStore.getSelectedTaskSection,
        },
      });
      return;
    }
    if (tasksStore.getSelectedTaskId) {
      router.push({
        name: "MyTask",
        params: {
          taskId: tasksStore.getSelectedTaskId,
        },
      });
      return;
    }
    router.push({ name: "MyTasks" });
  }
  if (route === "MyProjectsList") {
    if (
      currentRoute.name === "MyProjectTaskSection" ||
      currentRoute.name === "MyProjectTasks" ||
      currentRoute.name === "MyProjectTask"
    ) {
      projectStore.setSelectedProjectId(null);
      projectStore.setSelectedProjectTaskId(null);
      projectStore.setSelectedProjectTaskSection(null);
      router.push({ name: "MyProjectsList" });
      return;
    }
    if (
      projectStore.getSelectedProjectTaskId &&
      projectStore.getSelectedProjectId &&
      projectStore.getSelectedProjectTaskSection
    ) {
      router.push({
        name: "MyProjectTaskSection",
        params: {
          projectId: projectStore.getSelectedProjectId,
          taskId: projectStore.getSelectedProjectTaskId,
          section: projectStore.getSelectedProjectTaskSection,
        },
      });
      return;
    }
    if (
      projectStore.getSelectedProjectTaskId &&
      projectStore.getSelectedProjectId
    ) {
      router.push({
        name: "MyProjectTask",
        params: {
          projectId: projectStore.getSelectedProjectId,
          taskId: projectStore.getSelectedProjectTaskId,
        },
      });
      return;
    }
    if (projectStore.getSelectedProjectId) {
      router.push({
        name: "MyProjectTasks",
        params: {
          projectId: projectStore.getSelectedProjectId,
        },
      });
      return;
    }
    router.push({ name: "MyProjectsList" });
  }
  if (route === "MyHours") {
    router.push({ name: "MyHours" });
  }
  if (route === "MySettings") {
    router.push({ name: "MySettings" });
  }
}

const isPage = computed(() => {
  return (pages: string[]) => {
    if (pages.includes(String(router.currentRoute.value.name))) {
      return true;
    } else {
      return false;
    }
  };
});
</script>
<style lang="scss" scoped>
.nav-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  background-color: var(--color-background);
  // valid for image
  background-image: url("../assets/images/transparentHeader.png");
  background-repeat: no-repeat;
  background-position: center;
  background-position-y: -42px; // set to see the image border, if there is a problem, remove it
  background-size: cover cover;

  // background-size: 100% 100%;

  .wrapper {
    display: flex;
    // flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: var(--vt-c-header-height);
    min-height: fit-content;
    box-sizing: border-box;
    max-width: 1744px;
    // padding: 20px;
    padding: 0 20px;
    width: 100%;

    // position: relative;
    // background-image: url("../assets/images/headerBackground.png");
    // background-repeat: no-repeat;
    // background-position: center;
    // background-size: 100% 100%;

    // .wrapperIMG{
    //   position: absolute;
    //   width:100%;
    //   height: 100%;
    //   top:0;
    //   left:0;
    //   svg{
    //     width:100%;
    //     height: 100%;
    //   }
    // }

    .logo-wrapper,
    .profile-wrapper {
      width: 23%;
    }

    .logo-wrapper {
      gap: 10px;
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      justify-content: space-between;
      .flex-class {
        display: flex;
      }
    }

    .logo-wrapper {
      img {
        // height: 24px;
        margin-right: 10px;
        // width: 134px;
      }
    }

    nav {
      margin-top: 16px;
      width: 489px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      max-width: 100%;

      span {
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        color: var(--color-text);
        text-decoration: none;
        transition: 0.3s;
        cursor: pointer;
        border-bottom: 2px solid transparent;
        padding-left: 2px;
        padding-right: 2px;
        padding-bottom: 2px;
      }

      span.active {
        color: var(--vt-c-active);
        // border-bottom: 2px solid var(--vt-c-active);
      }
    }
  }

  .helper {
    // background: #242427;
    flex-grow: 1;
    height: 70px;
  }
}
</style>
