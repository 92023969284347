import api from "@/api";
import { useWindowSize } from "@vueuse/core";
import { readJwt, getRandomValueForBoundries } from "@/assets/js/helpers";
import type { JWTPayload, WebSocketMessage } from "@/assets/js/types";
import {
  FIVE_MINUTES_IN_MILIS,
  THIRTY_SECONDS_IN_MILIS,
  // WEB_SOCKET_CONNECTION_MAP,
  WS_MAP,
} from "@/assets/js/values";
// const FIVE_MINUTES_IN_MILIS = 28 * 1_000 * 60;
import type {
  WebSocketChatTypes,
  WebSocketNotificationTypes,
} from "@/assets/js/types";
import { defineStore } from "pinia";
import { DateInstance } from "@/assets/js/dateHelper";

export const defaultStore = defineStore("main", () => {
  const jwt = ref<string | null>(null);

  const abort_signal = ref<AbortController | null>(null);

  const context_menu_id_counter = ref(0);
  function getNewContextId() {
    context_menu_id_counter.value++;
    return context_menu_id_counter.value;
  }

  const getExpiry = computed((): number => {
    if (!jwt.value) {
      return 0;
    }
    const data: JWTPayload = readJwt(jwt.value);
    return data.exp * 1000;
  });
  const JWTRefreshIntervalId = ref<null | NodeJS.Timeout>(null);

  const cancelTimeoutId = ref<null | NodeJS.Timeout>(null);

  watchEffect(() => {
    if (!jwt.value) {
      if (JWTRefreshIntervalId.value) {
        clearInterval(JWTRefreshIntervalId.value);
      }
      return;
    }
    // const expired = getExpiry.value < Date.now();
    const near_expiry =
      getExpiry.value - DateInstance.getCurrentMilis() < FIVE_MINUTES_IN_MILIS;
    if (near_expiry) {
      checkJWTRefresh();
      return;
    }
    if (JWTRefreshIntervalId.value) {
      clearInterval(JWTRefreshIntervalId.value);
    }
    JWTRefreshIntervalId.value = setInterval(
      checkJWTRefresh,
      THIRTY_SECONDS_IN_MILIS
    );
  });

  function setJWT(payload: string) {
    jwt.value = payload;
  }

  async function checkJWTRefresh() {
    // eslint-disable-next-line no-storage/no-browser-storage
    const ls_jwt = localStorage.getItem("jwt");
    if (ls_jwt && ls_jwt !== jwt.value) {
      setJWT(ls_jwt);
      return;
    }
    if (
      getExpiry.value - DateInstance.getCurrentMilis() <
      FIVE_MINUTES_IN_MILIS
    ) {
      //? potentialy randomize timing of this api call because of multiple tabs
      //? probable fix is to include websocket message that fires on refreshed jwt, investigate further (add )
      //? another is to just check for a new jwt in localstorage in rapid fire since getLocalStorageReac is not that reactive
      window.addEventListener("storage", abortApiCall);
      abort_signal.value = new AbortController();
      const helper = abort_signal.value;
      cancelTimeoutId.value = setTimeout(
        () => refreshJWT(helper),
        getRandomValueForBoundries()
      );
    }
  }

  function abortApiCall(e: StorageEvent) {
    if (cancelTimeoutId.value) {
      cancelTimeoutId.value = null;
    }
    const key = e.key;
    if (key === "jwt") {
      if (!abort_signal.value) {
        throw new Error("invalid about signal");
      }
      abort_signal.value.abort();
      window.removeEventListener("storage", abortApiCall);
      abort_signal.value = null;

      // eslint-disable-next-line no-storage/no-browser-storage
      jwt.value = localStorage.getItem("jwt");
    }
  }

  async function refreshJWT(signal: AbortController) {
    if (cancelTimeoutId.value) {
      clearTimeout(cancelTimeoutId.value);
    }
    try {
      const res = await api.refreshJWT(signal.signal);
      window.removeEventListener("storage", abortApiCall);
      // eslint-disable-next-line no-storage/no-browser-storage
      localStorage.setItem("jwt", res.data.data);
      jwt.value = res.data.data;
    } catch (error) {
      console.log(error);
    } finally {
      if (abort_signal.value) {
        abort_signal.value = null;
      }
    }
  }

  // websockets
  // const notification_socket = ref<WebSocket | null>(null);
  // const message_socket = ref<WebSocket | null>(null);

  const socketMessages = ref<WebSocketMessage[]>([]);

  const getSocketMessages = computed(() => socketMessages.value);

  const getTaskMessages = computed(() => {
    return socketMessages.value.filter((ws_message) =>
      Object.values(WS_MAP.chat).includes(ws_message.type as WebSocketChatTypes)
    );
  });

  const getNotifications = computed(() => {
    return socketMessages.value.filter((ws_message) =>
      Object.values(WS_MAP.notification).includes(
        ws_message.type as WebSocketNotificationTypes
      )
    );
  });

  function connectWs(): void {
    // createNotificationSocketConnection();
    // createMessageSocketConnection();
  }

  // function createMessageSocketConnection(): WebSocket | null {
  //   return null;
  //   const supportsWebSockets = "WebSocket" in window;
  //   if (!supportsWebSockets) {
  //     return null;
  //   }
  //   if (
  //     message_socket.value &&
  //     message_socket.value.readyState === WEB_SOCKET_CONNECTION_MAP.OPEN
  //   ) {
  //     return message_socket.value;
  //   }
  //   const socketUrl = import.meta.env.VITE_APP_MESSAGE_SOCKET;
  //   if (!socketUrl) {
  //     throw new Error("Websocket URL not set");
  //   }

  //   socket.value = new WebSocket(socketUrl);
  //   socket.value.onmessage = (message) => {
  //   return message_socket.value;
  // }

  // function createNotificationSocketConnection(): WebSocket | null {
  //   const supportsWebSockets = "WebSocket" in window;
  //   if (!supportsWebSockets) {
  //     return null;
  //   }
  //   if (
  //     notification_socket.value &&
  //     notification_socket.value.readyState === WEB_SOCKET_CONNECTION_MAP.OPEN
  //   ) {
  //     return notification_socket.value;
  //   }
  //   const socketUrl = import.meta.env.VITE_APP_NOTIFICATION_SOCKET;
  //   if (!socketUrl) {
  //     throw new Error("Websocket URL not set");
  //   }

  //   const jwt = getLocalStorageReac("jwt");
  //   if (!jwt.value) {
  //     return null;
  //   }
  //   notification_socket.value = new WebSocket(socketUrl + "?jwt=" + jwt.value);
  //   notification_socket.value.onmessage = (message) => {
  //     let data = message.data;
  //     try {
  //       data = JSON.parse(data);
  //       socketMessages.value.push(data);
  //     } catch (error) {
  //       console.log("Invalid Websocket Message");
  //       console.error(error);
  //     }
  //   };

  //   return notification_socket.value;
  // }
  // for mobile
  const { width } = useWindowSize();
  const isMobileToggled = ref(false);
  const isMobileSize = computed(() => width.value < 1000); // 768
  const isMobileView = computed(
    () => isMobileSize.value && isMobileToggled.value
  );
  watch(isMobileSize, (val) => {
    console.log(width.value, "width----", isMobileSize.value);
    if (!val) {
      isMobileToggled.value = false;
    }
  });

  return {
    // createSocketConnection,
    getSocketMessages,
    getNewContextId,
    getTaskMessages,
    getExpiry,
    setJWT,
    connectWs,
    // getSocketMessages,
    // getTaskMessages,
    getNotifications,
    // mobile
    isMobileToggled,
    isMobileView,
    isMobileSize,
  };
});
