<script setup lang="ts">
import { TasksStore } from "@/store/tasksStore";
import { TransferStore } from "@/store/transferStore";
import api from "@/api";
import { CompanyStore } from "@/store/companyStore";
import { ModalStore } from "@/store/modalStore";
import { debounce, openCustomToast } from "@/assets/js/helpers";
import { type Modal, type Period } from "@/assets/js/types";
import dayjs from "dayjs";
import { usePagination } from "@/assets/js/composables";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { checkPermission } from "@/assets/js/helpers";

const { count, offset, onChange, resetPagination, page, perPage } =
  usePagination(getUserTasks);
const router = useRouter();
const tasksStore = TasksStore();
const transferStore = TransferStore();
const companyStore = CompanyStore();
const modalStore = ModalStore();
const tasks = ref([]);
const search = ref<string | null>(null);
type SortObj = {
  name: string;
  sort: string;
};
const currentMonth = dayjs().get("month");
const startOfMonth = dayjs().set("month", currentMonth).set("date", 1);
const endOfMonth = dayjs()
  .set("month", currentMonth + 1)
  .set("date", 0);
const sortTo = ref<SortObj | null>(null);
const end = ref(
  dayjs()
    .set("hour", 23)
    .set("minutes", 59)
    .set("seconds", 59)
    .format("YYYY-MM-DD HH:mm:ss")
);
const start = ref(
  dayjs()
    .set("hour", 0)
    .set("minutes", 0)
    .set("seconds", 0)
    .format("YYYY-MM-DD HH:mm:ss")
);
const period = ref<Period | null>({
  label: "All tasks",
  key: "all",
});
const all = ref(true);
const periodList = ref<Period[]>([
  {
    label: "All tasks",
    key: "all",
  },
  {
    label: "Tasks created today",
    key: "today",
    start: dayjs()
      .set("hour", 0)
      .set("minutes", 0)
      .set("seconds", 0)
      .format("YYYY-MM-DD HH:mm:ss"),
    end: dayjs()
      .set("hour", 23)
      .set("minutes", 59)
      .set("seconds", 59)
      .format("YYYY-MM-DD HH:mm:ss"),
  },
  {
    label: "Tasks created yesterday",
    key: "yesterday",
    start: dayjs()
      .subtract(1, "day")
      .set("hour", 0)
      .set("minutes", 0)
      .set("seconds", 0)
      .format("YYYY-MM-DD HH:mm:ss"),
    end: dayjs()
      .subtract(1, "day")
      .set("hour", 23)
      .set("minutes", 59)
      .set("seconds", 59)
      .format("YYYY-MM-DD HH:mm:ss"),
  },
  {
    label: "Tasks created in last 7 days",
    key: "week",
    start: dayjs()
      .subtract(7, "day")
      .set("hour", 0)
      .set("minutes", 0)
      .set("seconds", 0)
      .format("YYYY-MM-DD HH:mm:ss"),
    end: dayjs()
      .set("hour", 23)
      .set("minutes", 59)
      .set("seconds", 59)
      .format("YYYY-MM-DD HH:mm:ss"),
  },
  {
    label: "Tasks created this month",
    key: "month",
    start: startOfMonth.format("YYYY-MM-DD HH:mm:ss"),
    end: endOfMonth
      .set("hour", 23)
      .set("minutes", 59)
      .set("seconds", 59)
      .format("YYYY-MM-DD HH:mm:ss"),
  },
  {
    label: "Custom",
    key: "custom",
  },
]);

function setPeriod(newPeriod: Period): void {
  period.value = newPeriod;
  if (newPeriod.key === "all") {
    all.value = true;
  } else {
    all.value = false;
  }
  if (newPeriod.end) {
    end.value = newPeriod.end;
  }
  if (newPeriod.start) {
    start.value = newPeriod.start;
  }
  resetPagination();
}

function setCustomPeriod(): void {
  period.value = {
    label: "Custom",
    key: "custom",
  };
  resetPagination();
}

async function getUserTasks() {
  try {
    const params: any = {
      offset: offset.value - 1,
      limit: perPage.value,
      cmp_id: companyStore.activeCompany?.cmp_id,
      end: dayjs(end.value).utc().format("DD.MM.YYYY HH:mm:ss"),
      start: dayjs(start.value).utc().format("DD.MM.YYYY HH:mm:ss"),
    };
    if (search.value && search.value !== "") {
      params.search = search.value;
    }
    if (all.value) {
      params.view_all = true;
    }
    if (sortTo.value) {
      // params[sortTo.value.name] = sortTo.value.sort
      params.sort = JSON.stringify({
        name: sortTo.value.name,
        to: sortTo.value.sort,
      });
    }
    const res = await api.getUserTasks(params);
    tasks.value = res.data.tasks;
    count.value = res.data.meta_info.count;
  } catch (error) {
    console.log(error);
  }
}

watch(
  [() => companyStore.activeCompany, () => transferStore.refreshMyTasks],
  () => {
    getUserTasks();
  }
);

const onSearch = debounce(
  function () {
    resetPagination();
  },
  500,
  false
);

onMounted(() => {
  getUserTasks();
  if (router.currentRoute.value.params.taskId) {
    tasksStore.setSelectedTaskId(
      Number(router.currentRoute.value.params.taskId)
    );
  }
});

const getColumns = computed(() => {
  if (router.currentRoute.value.params.taskId) {
    return [
      {
        key: "tsk_name",
        type: "task-subtask",
        label: "task/subtask",
        style: {
          "max-width": "300px",
        },
      },
      {
        key: "tsk_priority",
        type: "priority",
        label: "Prio.",
        sort: true,
        // style: {
        //   "justify-content": "center",
        //   "text-align": "center",
        // },
      },
      // {
      //   key: "tsk_due",
      //   type: "date",
      //   label: "Due date",
      // },
      {
        key: "tsk_due",
        type: "date_due_created",
        label: "Due/Created date",
      },
      {
        key: "progress",
        type: "progress",
        label: "Progress / Hours",
        showHours: true,
      },
      {
        key: "tsk_id",
        type: "text",
        label: "Id",
        sort: true,
        style: {
          "max-width": "30px",
        },
      },
      // {
      //   key: "progress",
      //   type: "progress",
      //   label: "Progress",
      // },
      // {
      //   key: "hours",
      //   type: "hours",
      //   label: "Hours",
      // },
    ];
  } else {
    return [
      {
        key: "tsk_name",
        type: "task-subtask",
        label: "task/subtask",
        sort: true,
        style: {
          "max-width": "900px",
        },
      },
      // {
      //   key: "tsk_id",
      //   type: "text",
      //   label: "Id",
      //   style: {
      //     "max-width": "30px",
      //   },
      // },
      {
        key: "tsk_priority",
        type: "priority",
        label: "Prio.",
        sort: true,
        style: {
          width: "80px",
          // "justify-content": "center",
          // "text-align": "center",
        },
      },
      {
        key: "pro_name",
        type: "client-project",
        label: "project / client",
        // sort: true,
      },
      {
        key: "tsk_due",
        type: "date",
        label: "Due date",
        sort: true,
      },
      {
        key: "progress",
        type: "progress",
        label: "Progress / Hours",
        showHours: true,
      },
      // removed for now due to new design
      // it is displayed along with progress, hence the showHours parameter in progress
      // {
      //   key: "hours",
      //   type: "hours",
      //   label: "Hours",
      // },
      {
        key: "tsk_id",
        type: "text",
        label: "Id",
        sort: true,
        style: {
          "max-width": "30px",
        },
      },
      {
        key: "assigned_users",
        maxLength: 4,
        type: "avatar",
        label: "Assigned",
      },
      {
        key: "tsk_created",
        type: "avatar-date",
        label: "Created",
        sort: true,
      },
      {
        key: "editable",
        type: "edit",
        label: "",
      },
      {
        key: "deletable",
        type: "delete",
        label: "",
      },
    ];
  }
});

const options = reactive({
  title: "",
  noData: "Trenutno nemate taskova",
  uniqueProperty: "tsk_id",
  sortable: false,
  type: "task",
  // id: "tsk_id",
  id: ["tsk_id"],
  contextOptions: [
    { name: "Open task in project", to_do: "project" },
    { name: "Add hours", to_do: "add_hours" },
    // { name: "Delete task", to_do: "delete" },
    // { name: "Archive task", to_do: "archive" },
  ],
  columns: getColumns,
});

function openTask(data: any) {
  tasksStore.setSelectedTaskId(data.tsk_id);
  const params = JSON.parse(JSON.stringify(router.currentRoute.value.params));
  params.taskId = data.tsk_id;
  if (!params.section) {
    params.section = "details";
  }
  router.push({ name: "MyTaskSection", params });
}
function closeTask() {
  tasksStore.setSelectedTaskId(null);
  tasksStore.setSelectedTaskSection(null);
  router.push({ name: "MyTasks" });
}

function openConfirmModal(task: any) {
  const data: Modal = {
    component: "confirmModal",
    title: "Delete task?",
    options: {
      confirmText: "Confirm",
      subtitle: {
        template: `
          Task: <strong>{{task.tsk_name}} </strong><br>
          From project <strong>"{{task.pro_name}}"</strong><br>
        `,
        data() {
          return { task };
        },
      },
      confirm: () => {
        deleteTask(task);
      },
      cancelText: "Cancel",
      cancel: () => {},
    },
  };
  modalStore.setModalData(data);
}

async function deleteTask(task: any) {
  try {
    await api.deleteTask(task.tsk_id);
    openCustomToast("You have successfully deleted the task.", "success");
  } catch (error) {
    console.log(error);
    openCustomToast("An error occurred while deleting the task.", "error");
  } finally {
    getUserTasks();
  }
}

function openEditModal(task: any) {
  const data: Modal = {
    component: "editTaskModal",
    title: "Edit task",
    options: {
      data: task,
      save: () => {
        getUserTasks();
      },
    },
  };
  modalStore.setModalData(data);
}

function sort(e) {
  //  ASC|DESC
  console.log(e);
  //  sortTo.value = {name:'sort_'+e, sort: 'ASC'}
  if (sortTo.value === null) {
    sortTo.value = { name: e, sort: "ASC" };
  } else if (sortTo.value !== null && sortTo.value.name !== e) {
    sortTo.value = { name: e, sort: "ASC" };
  } else if (sortTo.value.name === e) {
    sortTo.value = {
      name: e,
      sort: sortTo.value.sort === "ASC" ? "DESC" : "ASC",
    };
  }
  // getUserTasks();
  resetPagination();
}
</script>

<template>
  <section class="page-wrapper">
    <div
      class="page"
      :class="{ active: router.currentRoute.value.params.taskId }"
    >
      <div class="header">
        <router-link
          v-if="checkPermission(['availability-view'])"
          :to="{
            name: 'MyCoworkers',
            params: { companyId: companyStore.activeCompany?.cmp_id },
          }"
          class="global-btn"
        >
          My coworkers
        </router-link>
        <basic-search
          v-model="search"
          placeholder="Search"
          style="align-self: flex-end"
          @update:model-value="onSearch"
        />
        <basic-dropdown
          class="types"
          :list="periodList"
          :selected="period"
          :searchable="true"
          :labelKey="'label'"
          title="Period"
          style="margin-left: 20px; align-self: flex-end; width: 220px"
          @selected="setPeriod"
        />
        <!-- <basic-input
          v-if="!(period && period.key === 'all')"
          v-model="start"
          label="Date from"
          type="date"
          style="margin-left: 20px"
          @update:model-value="setCustomPeriod"
        />
        <basic-input
          v-if="!(period && period.key === 'all')"
          v-model="end"
          label="Date to"
          type="date"
          style="margin-left: 20px"
          @update:model-value="setCustomPeriod"
        /> -->
        <div
          v-if="!(period && period.key === 'all')"
          class="datepicker-wrapper small"
        >
          <div class="datepicker-label">Date from</div>
          <Datepicker
            v-model="start"
            :enable-time-picker="false"
            :clearable="false"
            format="dd.MM.yyyy"
            :auto-apply="true"
            :dark="true"
            input-class-name="datepicker-input"
            menu-class-name="datepicker-dropdown"
            :max-date="end"
            @update:model-value="setCustomPeriod"
          />
        </div>
        <div
          v-if="!(period && period.key === 'all')"
          class="datepicker-wrapper small"
        >
          <div class="datepicker-label">Date to</div>
          <Datepicker
            v-model="end"
            :enable-time-picker="false"
            :clearable="false"
            format="dd.MM.yyyy"
            :auto-apply="true"
            :dark="true"
            input-class-name="datepicker-input"
            menu-class-name="datepicker-dropdown"
            :min-date="start"
            @update:model-value="setCustomPeriod"
          />
        </div>
      </div>
      <table-wrapper
        :data="tasks"
        :options="options"
        :sortTo="sortTo"
        style="margin-bottom: 20px"
        @row-click="openTask"
        @search="onSearch"
        @delete-row="openConfirmModal"
        @edit="openEditModal"
        @sort="sort"
      />
      <pagination-one
        v-if="count > 0"
        v-model:page="page"
        :perPage="perPage"
        :offset="offset"
        :count="count"
        prevText="Prev"
        nextText="Next"
        style="margin-top: auto"
        @page-changed="onChange"
      />
    </div>
    <aside :class="{ active: router.currentRoute.value.params.taskId }">
      <task-wrapper @get-tasks="getUserTasks" @close="closeTask" />
    </aside>
  </section>
</template>

<style lang="scss" scoped>
.page-wrapper {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  transition: 0.3s;
  height: var(--vt-c-page-height);
  max-width: 1744px;
  margin: 0 auto;

  .page {
    width: 100%;
    transition: 0.3s;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
    padding: 20px;

    .header {
      display: flex;
      flex-direction: row;
      // justify-content: flex-start;
      justify-content: center;
      align-items: end;
      width: 100%;
    }
  }

  .page.active {
    width: 50%;
    padding: 20px 40px 20px 20px;
  }

  aside {
    // flex-grow: 1;
    // visibility: hidden;
    transition: 0.3s;
    width: 0%;
    overflow: hidden;
    box-sizing: border-box;
  }

  aside.active {
    width: 50%;
    // border-left: 1px solid var(--color-border);
    padding: 20px 20px 20px 40px;
  }
  .global-btn {
    margin-right: 20px;
  }
}

@media only screen and (width < 900px) {
  .page-wrapper {
    .page.active {
      padding: 20px 20px 20px 20px;
    }
    .page {
      .header {
        flex-wrap: wrap;
        justify-content: flex-start;
        .search-one {
          margin: 10px 0;
        }
        .outer-dropdown-wrapper.types {
          margin-left: 0px !important;
        }
      }
    }
    aside.active {
      padding: 20px 20px 20px 20px;
    }
  }
}
</style>
