<template>
  <section class="page-wrapper">
    <div
      v-if="checkPermission(['task-view'])"
      class="page"
      :class="{ active: router.currentRoute.value.params.taskId }"
    >
      <div class="navigate-back">
        <img
          src="@/assets/icons/navigateBack.svg"
          alt="navigate back"
          @click="navigateBack"
        />
        <span @click="navigateBack">
          {{
            projectData?.tsk_name ? projectData.tsk_name : "Back to projects"
          }}
        </span>
        <basic-button
          v-if="
            checkPermission(['task-manage'], false) &&
            transferStore.tasksClipboardNotEmpty
          "
          :label="'Paste task into root'"
          :image="'plus'"
          :imageDisabled="'plus'"
          style="align-self: center; white-space: nowrap; margin-left: auto"
          @click="transferStore.pasteTasks(projectData)"
        />
        <basic-button
          v-if="checkPermission(['task-add', 'task-manage'], false)"
          :label="'Task'"
          :image="'plus'"
          :imageDisabled="plus"
          style="align-self: center; white-space: nowrap"
          :style="{
            marginLeft:
              checkPermission(['task-manage'], false) &&
              transferStore.tasksClipboardNotEmpty
                ? '20px'
                : 'auto',
          }"
          class="btn-margin-left-auto"
          @click="openCreateModal('task')"
        />
        <!-- button za sub task promeni uslov -->
        <basic-button
          v-if="
            checkPermission(['task-add', 'task-manage'], false) &&
            router.currentRoute.value.params.taskId
          "
          :disabled="
            selectedTask !== null && selectedTask.tsk_locked !== null
              ? true
              : false
          "
          :label="'Subtask'"
          :image="'plus'"
          :imageDisabled="'plus'"
          style="align-self: center; white-space: nowrap; margin-left: 20px"
          @click="openCreateModal('subtask')"
        />
      </div>
      <div class="actions-wrapper">
        <basic-search
          v-model="taskStore.search"
          placeholder="Search"
          @update:model-value="taskStore.onSearch"
        />
        <basic-checkbox v-model="taskStore.archived" label="Deleted/Archived" />
      </div>
      <table-wrapper
        :data="taskStore.tasks"
        :options="options"
        :parentTree="taskStore.parentTree"
        style="margin-bottom: 20px"
        @row-click="openTask"
        @delete-row="openConfirmModal"
        @edit="openEditModal"
        @set-lock="lock"
        @emit-create="emitCreate"
      />
      <pagination-one
        v-if="taskStore.count > 0"
        v-model:page="taskStore.page"
        :perPage="taskStore.perPage"
        :offset="taskStore.offset"
        :count="taskStore.count"
        prevText="Prev"
        nextText="Next"
        style="margin-top: auto"
        @page-changed="taskStore.onChange"
      />
    </div>
    <aside
      v-if="checkPermission(['task-view'])"
      :class="{ active: router.currentRoute.value.params.taskId }"
    >
      <task-wrapper
        :changeData="changeData"
        @get-tasks="taskStore.getTasks"
        @close="closeTask"
        @set-task="setTask"
      />
    </aside>
    <div v-if="!checkPermission(['task-view'])" class="page">
      <div class="navigate-back" @click="navigateBack">
        <img src="@/assets/icons/navigateBack.svg" alt="navigate back" />
        <span>
          {{
            projectData?.tsk_name ? projectData.tsk_name : "Back to projects"
          }}
        </span>
      </div>
      <h1 style="align-self: flex-start">No privilege</h1>
    </div>
  </section>
</template>

<script setup lang="ts">
import plus from "@/components/elements/table/plus.svg";
import { ProjectStore } from "@/store/projectStore";
import { ModalStore } from "@/store/modalStore";
import api from "@/api";
import { openCustomToast, checkPermission } from "@/assets/js/helpers";
import { CompanyStore } from "@/store/companyStore";
import { type Modal } from "@/assets/js/types";
import dayjs from "dayjs";
import { TasksStore } from "@/store/tasksStore";
import { TransferStore } from "@/store/transferStore";

const modalStore = ModalStore();
const projectStore = ProjectStore();
const companyStore = CompanyStore();
const router = useRouter();
const taskStore = TasksStore();
const projectData = ref<any>(null);
// const showLocked = ref(false);
const transferStore = TransferStore();
const changeData = ref(false);
const selectedTask = ref<any>(null);

watch(
  [() => router.currentRoute.value.params.taskId, () => taskStore.tasks],
  ([newTaskId, newTasks], [oldTaskId, oldTasks]) => {
    addPasteTask(transferStore.tasksClipboardNotEmpty);
    addPasteHour(transferStore.hoursClipboardNotEmpty);
    if (newTaskId !== oldTaskId) {
      taskStore.getParentTree();
    }

    if (newTasks !== oldTasks && oldTasks.length !== 0) {
      // setSelectedTaskData(selectedTask.value?.tsk_id);
    } else if (
      oldTasks.length === 0 &&
      router.currentRoute.value.params.taskId
    ) {
      //  setSelectedTaskData(Number(router.currentRoute.value.params.taskId));
    }
  }
);

// function setSelectedTaskData(id = -1) {
//   for (let i = 0; i < taskStore.tasks.length; i++) {
//     if (taskStore.tasks[i].tsk_id === id) {
//       selectedTask.value = taskStore.tasks[i];
//     }
//   }
// }
function setTask(e: any) {
  selectedTask.value = e;
}

const getColumns = computed(() => {
  if (router.currentRoute.value.params.taskId) {
    return [
      {
        key: "has_childen",
        type: "dropdown",
        dropdown: true,
        label: "Task",
        style: {
          "max-width": "300px",
        },
      },
      {
        key: "tsk_priority",
        type: "priority",
        label: "Prio.",
        // style: {
        //   "justify-content": "center",
        //   "text-align": "center",
        // },
      },
      {
        key: "tsk_due",
        type: "date_due_created",
        label: "Due/Created date",
      },
      // {
      //   key: "tsk_due",
      //   type: "date",
      //   label: "Due date",
      // },
      // {
      //   key: "progress",
      //   type: "progress",
      //   label: "Progress",
      // },
      {
        key: "progress",
        type: "progress",
        label: "Progress / Hours",
        showHours: true,
      },
      {
        key: "tsk_id",
        type: "text",
        label: "Id",
        // sort: true,
        style: {
          "max-width": "30px",
        },
      },
      // {
      //   key: "hours",
      //   type: "hours",
      //   label: "Hours",
      //   style: {
      //     "justify-content": "center",
      //     "text-align": "center",
      //   },
      // },
    ];
  } else {
    return [
      {
        key: "has_childen",
        dropdown: true,
        type: "dropdown",
        label: "Task",
        style: {
          "max-width": "600px",
        },
      },
      {
        key: "tsk_priority",
        type: "priority",
        label: "Priority",
        style: {
          width: "80px",
          //   "justify-content": "center",
          //   "text-align": "center",
        },
      },
      {
        key: "tsk_due",
        type: "date",
        label: "Due date",
      },
      {
        key: "progress",
        type: "progress",
        label: "Progress / Hours",
        showHours: true,
      },
      // {
      //   key: "hours",
      //   type: "hours",
      //   label: "Hours",
      //   style: {
      //     "justify-content": "center",
      //     "text-align": "center",
      //   },
      // },
      {
        key: "assigned_users",
        type: "avatar",
        label: "Assigned",
      },
      {
        key: "tsk_created",
        type: "avatar-date-owner",
        label: "Created",
        sort: true,
      },
      // {
      //   key: "owner",
      //   type: "created-by",
      //   label: "Created",
      // },
      {
        key: "editable",
        type: "edit",
        label: "",
      },
      {
        key: "deletable",
        type: "delete",
        label: "",
      },
    ];
  }
});

const options = reactive<any>({
  title: "",
  editable: true,
  deletable: true,
  uniqueProperty: "tsk_id",
  sortable: true,
  watchArchived: true,
  type: "task",
  id: ["tsk_id"],
  columns: getColumns,
  contextOptions: [],
});

if (checkPermission(["projects-add"])) {
  options.contextOptions.push(
    { name: "Lock/unlock task", to_do: "lock" },
    { name: "Create subtask", to_do: "create_subtask" }
    // { name: "Archive task", to_do: "archive" },
  );
}
if (checkPermission(["project-manage"])) {
  options.contextOptions.push(
    {
      name: "Copy task to different project",
      to_do: "copy-task",
    },
    {
      name: "Cut task to different project",
      to_do: "cut-task",
    }
  );
}

function addPasteTask(not_empty) {
  if (not_empty) {
    if (!options.contextOptions.some((el) => el.to_do === "paste-task")) {
      options.contextOptions.push({
        name: "Paste task",
        to_do: "paste-task",
      });
    }
  } else {
    const index = options.contextOptions.findIndex(
      (el) => el.to_do === "paste-task"
    );
    if (index !== -1) {
      options.contextOptions.splice(index, 1);
    }
  }
}

function addPasteHour(not_empty) {
  if (not_empty) {
    if (!options.contextOptions.some((el) => el.to_do === "paste-hour")) {
      options.contextOptions.push({
        name: "Paste hour",
        to_do: "paste-hour",
      });
    }
  } else {
    const index = options.contextOptions.findIndex(
      (el) => el.to_do === "paste-hour"
    );
    if (index !== -1) {
      options.contextOptions.splice(index, 1);
    }
  }
}

watch(() => transferStore.tasksClipboardNotEmpty, addPasteTask);
watch(() => transferStore.hoursClipboardNotEmpty, addPasteHour);

onMounted(() => {
  taskStore.resetPagination();
  taskStore.getTasks();
  if (router.currentRoute.value.params.projectId) {
    projectStore.setSelectedProjectId(
      Number(router.currentRoute.value.params.projectId)
    );
    getProjectData();
  }

  if (router.currentRoute.value.params.taskId) {
    projectStore.setSelectedProjectTaskId(
      Number(router.currentRoute.value.params.taskId)
    );
  }

  taskStore.search = "";
});

async function getProjectData() {
  try {
    const params: any = {
      cmp_id: companyStore.getActiveCompany?.cmp_id,
    };
    const res = await api.getTaskDetails(
      params,
      Number(router.currentRoute.value.params.projectId)
    );
    projectData.value = res.data.task;
  } catch (error) {
    console.log(error);
  }
}

function openTask(task: any): void {
  // if (task.tsk_locked) {
  //   return;
  // }
  selectedTask.value = task;
  const params = JSON.parse(JSON.stringify(router.currentRoute.value.params));
  params.taskId = Number(task.tsk_id);
  if (!params.section) {
    params.section = "details";
  }
  projectStore.setSelectedProjectTaskId(task.tsk_id);
  projectStore.setSelectedProjectTaskSection("details");
  router.push({ name: "MyProjectTaskSection", params });
}

function closeTask() {
  projectStore.setSelectedProjectTaskId(null);
  projectStore.setSelectedProjectTaskSection(null);
  router.push({ name: "MyProjectTasks" });
}

function navigateBack() {
  projectStore.setSelectedProjectId(null);
  projectStore.setSelectedProjectTaskId(null);
  projectStore.setSelectedProjectTaskSection(null);
  router.push({ name: "MyProjectsList" });
}

async function save(data: any, type: string) {
  const params: any = {
    tsk_name: data.title,
    tsk_id_parent:
      type === "task"
        ? router.currentRoute.value.params.projectId
        : router.currentRoute.value.params.taskId,
  };
  if (data.due_date) {
    params.tsk_due = dayjs(data.due_date).utc().format();
  }
  if (data.assign && data.assign.length > 0) {
    params.assigned = JSON.stringify(
      data.assign.map((user: any) => user.usr_id)
    );
  }
  if (data.description) {
    params.tsk_description = data.description;
  }
  if (data.priority) {
    params.tsk_priority = data.priority.value;
  }
  if (data.tsk_color) {
    params.tsk_color = data.tsk_color;
  }
  try {
    const res = await api.createTask(params);
    openCustomToast("You have successfully created a new task.", "success");
    if (data.hours) {
      openHoursModal(res.data.data.tsk_name, res.data.data.tsk_id);
    }
  } catch (error) {
    console.log(error);
    openCustomToast("An error occurred while creating the task.", "error");
  } finally {
    taskStore.getTasks();
  }
}

function openHoursModal(tsk_name: string, tsk_id: number) {
  const data: Modal = {
    component: "addHoursModal",
    title: "Add hours",
    options: {
      data: {
        tsk_name,
        tsk_id,
      },
    },
  };
  modalStore.setModalData(data);
}

function openCreateModal(type: string) {
  const data: any = {
    component: "newTaskModal",
    title: type === "task" ? "Create task" : "Create subtask",
    options: {
      save: (data: any) => {
        save(data, type);
      },
    },
  };
  modalStore.setModalData(data);
}

function openConfirmModal(task: any) {
  if (task.tsk_locked) {
    return;
  }
  const data: Modal = {
    component: "confirmModal",
    title: "Delete task?",
    options: {
      confirmText: "Confirm",
      subtitle: {
        template: `Task: <strong>{{task.tsk_name}} </strong><br>`,
        data() {
          return { task };
        },
      },
      confirm: () => {
        deleteTask(task);
      },
      cancelText: "Cancel",
      cancel: () => {},
    },
  };
  modalStore.setModalData(data);
}

async function deleteTask(task: any) {
  try {
    await api.deleteTask(task.tsk_id);
    openCustomToast("You have successfully deleted the task.", "success");
  } catch (error) {
    console.log(error);
    openCustomToast("An error occurred while deleting the task.", "error");
  } finally {
    taskStore.getTasks();
  }
}
async function lock(params) {
  try {
    await api.editTask(params, params.tsk_id);

    taskStore.getTasks();
    changeData.value = !changeData.value;
    // setTimeout(() => {
    // openTask(params);
    // }, 1000);
  } catch (error) {
    console.log(error);
  }
}
function emitCreate(e) {
  console.log(e);
  openTask({ tsk_id: e });
  openCreateModal("subtask");
}

function openEditModal(task: any) {
  if (task.tsk_locked) {
    return;
  }
  const data: Modal = {
    component: "editTaskModal",
    title: "Edit task",
    options: {
      data: task,
      save: () => {
        taskStore.getTasks();
      },
    },
  };
  modalStore.setModalData(data);
}
</script>
<style lang="scss" scoped>
.page-wrapper {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  transition: 0.3s;
  height: var(--vt-c-page-height);
  max-width: 1744px;
  margin: 0 auto;

  .page {
    width: 100%;
    transition: 0.3s;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
    padding: 20px 40px 20px 20px;
  }

  .page.active {
    width: 50%;
  }

  aside {
    // flex-grow: 1;
    // visibility: hidden;
    transition: 0.3s;
    width: 0%;
    overflow: hidden;
    box-sizing: border-box;
  }

  aside.active {
    width: 50%;

    // border-left: 1px solid var(--color-border);
    padding: 27px 20px 20px 40px;
  }
}

.navigate-back {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: flex-start;
  margin-bottom: 20px;
  .btn-margin-left-auto {
    margin-left: auto;
  }

  span {
    color: var(--vt-c-active);
    font-weight: 700;
    font-size: 20px;
    line-height: 22px;
    margin-left: 13px;
    cursor: pointer;
  }

  img {
    height: 20px;
    cursor: pointer;
  }
}

.actions-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;

  .container:hover {
    background: initial;
  }

  .container {
    box-sizing: initial;
    min-height: 22px;
    margin-left: 20px;
  }
}

@media only screen and (width < 900px) {
  .page-wrapper {
    .page {
      padding: 20px 20px 20px 20px;
    }
    aside.active {
      padding: 20px 20px 20px 20px;
    }
    .navigate-back {
      flex-wrap: wrap;
      align-items: normal;
      .btn-margin-left-auto {
        margin-left: 20px !important;
      }
      .btn-wrapper-two {
        margin-top: 5px;
      }
    }
    .actions-wrapper {
      flex-wrap: wrap;
      .container {
        // box-sizing: initial;
        // min-height: 22px;
        margin-left: 0;
      }
    }
  }
}
</style>
