<script setup lang="ts">
import { defaultStore } from "@/store";
import { useRouter } from "vue-router";
import { ProjectStore } from "../../store/projectStore";
import { TasksStore } from "../../store/tasksStore";
import { CompanyStore } from "../../store/companyStore";

export interface IUniTabProp {
  id?: number | string;
  name: string;
  label: string;
  icon?: string;
  routeName?: string;
  routeParams?: any;
  activeIf: string[];
  routeQuery?: any;
  isDisabled?: boolean;
  isInactive?: boolean;
  isLocked?: boolean;
  isDirty?: boolean;
  isError?: boolean;
  isClosable?: boolean;
  isPinned?: boolean;
  canShowDirty?: boolean;
}
const navList = ref<IUniTabProp[]>([
  {
    name: "MyTasks",
    label: "My tasks",
    routeName: "MyTasks",
    activeIf: ["MyTasks", "MyTask", "MyTaskSection"],
  },
  {
    name: "MyProjectsList",
    label: "Projects",
    routeName: "MyProjectsList",
    activeIf: [
      "MyProjectsList",
      "MyProjectTasks",
      "MyProjectTask",
      "MyProjectTaskSection",
    ],
  },
  {
    name: "MyHours",
    label: "Timesheets",
    routeName: "MyHours",
    activeIf: ["MyHours"],
  },
  {
    name: "MySettings",
    label: "Settings",
    routeName: "MySettings",
    activeIf: ["MySettings"],
  },
]);
const companyStore = CompanyStore();
const projectStore = ProjectStore();
const tasksStore = TasksStore();
const router = useRouter();
const currentRoute = useRoute();
const store = defaultStore();

const pageName = computed(() => {
  const name = navList.value.find((page) =>
    page.activeIf.includes(String(router.currentRoute.value.name))
  );
  console.log(name);
  return name?.label ?? "";
});

function navigate(routeObj: IUniTabProp): void {
  store.isMobileToggled = false;
  let route = routeObj.routeName;
  if (route === "MyTasks") {
    if (tasksStore.getSelectedTaskId && tasksStore.getSelectedTaskSection) {
      router.push({
        name: "MyTaskSection",
        params: {
          taskId: tasksStore.getSelectedTaskId,
          section: tasksStore.getSelectedTaskSection,
        },
      });
      return;
    }
    if (tasksStore.getSelectedTaskId) {
      router.push({
        name: "MyTask",
        params: {
          taskId: tasksStore.getSelectedTaskId,
        },
      });
      return;
    }
    router.push({ name: "MyTasks" });
  }
  if (route === "MyProjectsList") {
    if (
      currentRoute.name === "MyProjectTaskSection" ||
      currentRoute.name === "MyProjectTasks" ||
      currentRoute.name === "MyProjectTask"
    ) {
      projectStore.setSelectedProjectId(null);
      projectStore.setSelectedProjectTaskId(null);
      projectStore.setSelectedProjectTaskSection(null);
      router.push({ name: "MyProjectsList" });
      return;
    }
    if (
      projectStore.getSelectedProjectTaskId &&
      projectStore.getSelectedProjectId &&
      projectStore.getSelectedProjectTaskSection
    ) {
      router.push({
        name: "MyProjectTaskSection",
        params: {
          projectId: projectStore.getSelectedProjectId,
          taskId: projectStore.getSelectedProjectTaskId,
          section: projectStore.getSelectedProjectTaskSection,
        },
      });
      return;
    }
    if (
      projectStore.getSelectedProjectTaskId &&
      projectStore.getSelectedProjectId
    ) {
      router.push({
        name: "MyProjectTask",
        params: {
          projectId: projectStore.getSelectedProjectId,
          taskId: projectStore.getSelectedProjectTaskId,
        },
      });
      return;
    }
    if (projectStore.getSelectedProjectId) {
      router.push({
        name: "MyProjectTasks",
        params: {
          projectId: projectStore.getSelectedProjectId,
        },
      });
      return;
    }
    router.push({ name: "MyProjectsList" });
  }
  if (route === "MyHours") {
    router.push({ name: "MyHours" });
  }
  if (route === "MySettings") {
    router.push({ name: "MySettings" });
  }
}

// async function onClickedMenuItem(item: any) {
//   store.isMobileToggled = false;

//   await router.push({
//     name: item.routeName,
//   });
// }
</script>

<template>
  <div class="header-bar">
    <div class="left-side">
      <router-link class="logo-wrapper" to="/" draggable="false">
        <img class="logo" src="@/assets/images/OT.svg" draggable="false" />
      </router-link>
    </div>
    <div class="center">
      <div class="menu">{{ pageName }}</div>
    </div>
    <div class="right-side">
      <div class="mobile-menu-wrap">
        <burger-menu-btn
          :isMobileHeaderToggled="store.isMobileToggled"
          @toggle-burger="store.isMobileToggled = !store.isMobileToggled"
        />
        <transition name="slide-fade">
          <header-mobile
            v-if="store.isMobileToggled"
            :navList="navList"
            @click-menu-item="navigate"
            @close="store.isMobileToggled = false"
          ></header-mobile>
        </transition>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.header-bar {
  display: flex;
  gap: 10px;
  // background: $background-color;
  height: $nav-top-bar;
  min-height: $nav-top-bar;
  padding: 0 15px;
  background-color: var(--color-background);

  // TODO 1 See to reintroduce this
  // max-width: min(1500px, 100vw);
  // width: 100%;
  // align-self: center;
  position: sticky;
  top: 0;
  z-index: 10;
  border-bottom: 1px solid rgb(128, 128, 128, 0.1);
  box-sizing: content-box; // Because of the border-bottom

  &::before {
    content: "";
    backdrop-filter: blur(5px);
    position: absolute;
    inset: 0;
    z-index: -1; // To be in the background
  }

  .left-side,
  .center,
  .right-side {
    flex: 1;
    padding: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
    white-space: nowrap;
  }

  .center {
    font-weight: 500;
    align-items: center;
    padding: 0;
    justify-content: center;

    .menu {
      font-size: 21px;
      color: var(--color-text);
    }
  }

  .logo-wrapper {
    display: flex;
  }

  .right-side {
    justify-content: flex-end;
    gap: 15px;

    button {
      width: 150px;
    }
  }

  // .menu {
  //   gap: 10px;

  //   :deep() {
  //     .uni-tabs-wrap {
  //       padding: 0;
  //     }

  //     .uni-tabs {
  //       gap: 15px;
  //     }

  //     .line-wrapper {
  //       margin-bottom: 19px;

  //       .line {
  //         height: 10px;
  //         border-radius: 0 0 20px 20px;
  //         filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.1));
  //       }
  //     }

  //     .tab {
  //       font-size: 12px;
  //       font-weight: 300;
  //       font-family: "Open Sans", sans-serif;
  //       padding: 0;
  //     }
  //   }

  //   a {
  //     color: inherit;
  //     text-decoration: none;
  //   }
  // }

  .mobile-menu-wrap {
    display: flex;
  }

  .slide-fade-enter-active,
  .slide-fade-leave-active {
    transition: all 0.3s ease;
  }

  .slide-fade-enter,
  .slide-fade-enter-from,
  .slide-fade-leave-to {
    transform: translateY(10px);
    opacity: 0;
  }

  // @media screen and (width >= 768px) {
  //   .menu {
  //     display: flex;

  //     :deep() {
  //       .tab {
  //         font-size: 13px;
  //       }
  //     }
  //   }

  //   .right-side {
  //     display: flex;
  //   }

  //   // .mobile-menu-wrap {
  //   //   display: none;
  //   // }
  // }

  // @media screen and (width >= 1024px) {
  //   .menu {
  //     :deep() {
  //       .tab {
  //         font-size: 16px;
  //       }
  //     }
  //   }

  //   .right-side {
  //     display: flex;
  //   }

  //   // .mobile-menu-wrap {
  //   //   display: none;
  //   // }
  // }
}
</style>
