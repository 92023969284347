<template>
  <div class="add-hours-wrapper">
    <div
      class="section task-name"
      :style="{
        '--task-color': '#' + props.options?.data?.tsk_color,
      }"
    >
      <div class="input-wrapper">
        <h1>
          {{ props.options?.data?.tsk_name }}
        </h1>
      </div>
    </div>
    <div class="section first-content">
      <div
        class="input-wrapper space"
        :style="{ 'margin-left': '0px', width: '200px' }"
      >
        <BasicDropdown
          class="types"
          :list="taskTypes"
          :selected="params.taskType"
          :searchable="true"
          :labelKey="'ttp_name'"
          :title="'Task type'"
          :focus-input="true"
          @selected="setTaskType"
        />
      </div>
      <div class="time-central-content-new space">
        <span class="cc-desc">Time</span>
        <div class="time-buttons-wrapper">
          <TimeButton
            v-model="params.hours"
            placeholder="HH"
            label="Hours"
            :min="0"
            :max="16"
            :step="1"
            :maxValueCharacter="2"
            style="margin-right: 10px"
          />
          :
          <TimeButton
            v-model="params.minutes"
            placeholder="MM"
            label="Minutes"
            :min="0"
            :max="60"
            :step="15"
            :maxValueCharacter="2"
            style="margin-left: 10px"
          />
        </div>
      </div>
      <div
        v-if="checkPermission(['hours-approve'])"
        class="time-central-content-new approved-time space"
      >
        <span class="cc-desc">Approved</span>
        <div class="time-buttons-wrapper">
          <TimeButton
            v-model="params.hours_approved"
            placeholder="HH"
            label="Hours"
            :min="0"
            :max="16"
            :step="1"
            :maxValueCharacter="2"
            style="margin-right: 10px"
          />
          :
          <TimeButton
            v-model="params.minutes_approved"
            placeholder="MM"
            label="Minutes"
            :min="0"
            :max="60"
            :step="15"
            :maxValueCharacter="2"
            style="margin-left: 10px"
          />
        </div>
      </div>
      <div
        v-if="checkPermission(['project-manage'])"
        class="time-central-content-new approved-time space"
      >
        <span class="cc-desc">Billed</span>
        <div class="time-buttons-wrapper">
          <TimeButton
            v-model="params.hours_billed"
            placeholder="HH"
            label="Hours"
            :min="0"
            :max="16"
            :step="1"
            :maxValueCharacter="2"
            style="margin-right: 10px"
          />
          :
          <TimeButton
            v-model="params.minutes_billed"
            placeholder="MM"
            label="Minutes"
            :min="0"
            :max="60"
            :step="15"
            :maxValueCharacter="2"
            style="margin-left: 10px"
          />
        </div>
      </div>
      <div class="central-content" style="margin-right: 0">
        <div class="input-wrapper types space">
          <BasicDropdown
            :style="{ width: '120px' }"
            class="hour-types"
            :list="hourTypes"
            :selected="params.hourType"
            :searchable="true"
            :labelKey="'tst_name'"
            :title="'Time type'"
            @selected="setHourType"
          />
        </div>
      </div>
      <div
        v-if="checkPermission(['hours-manage'])"
        class="central-content"
        style="margin-right: 0"
      >
        <div
          class="input-wrapper types space"
          :style="{ 'margin-right': '0px' }"
        >
          <BasicDropdown
            :style="{ width: '220px' }"
            class="assigned-to"
            :list="users"
            :selected="user"
            :searchable="true"
            :labelKey="'usr_displayname'"
            :title="'Assigned to'"
            @selected="setUser"
          />
        </div>
      </div>
    </div>
    <div class="section central-content keywords">
      <div
        class="central-content"
        :class="{ 'no-margin': !checkPermission(['hours-manage']) }"
        style="flex-grow: 1"
      >
        <div class="input-wrapper" style="flex-grow: 1">
          <BasicInput
            v-model="params.tms_shortinfo"
            placeholder="Your keywords here..."
            label="Keywords / short info"
            type="text"
          />
        </div>
      </div>
    </div>
    <div class="section description">
      <div class="input-wrapper">
        <BasicInput
          ref="dropdown"
          v-model="params.tms_description"
          placeholder="Your description here..."
          label="Description"
          type="textarea"
          class="desc-input"
          @input="checkDescriptionLength"
        />
      </div>
    </div>

    <div class="confirmation">
      <div class="btn-wrapper cancel">
        <BasicButton
          style="width: 100px"
          label="Cancel"
          :inv="true"
          @click="close"
        />
      </div>
      <div class="btn-wrapper">
        <BasicButton style="width: 100px" label="Save" @click="saveHours" />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import api from "@/api";
import { ModalStore } from "@/store/modalStore";
import { checkPermission, openCustomToast } from "@/assets/js/helpers";
import { CompanyStore } from "@/store/companyStore";
import { HoursStore } from "@/store/hoursStore";
import { type TaskType, type HourType } from "@/assets/js/types";
// import { watchEffect } from "vue";

defineEmits(["close"]);
const props = defineProps(["options"]);

const modalStore = ModalStore();
const companyStore = CompanyStore();
const hoursStore = HoursStore();

const taskTypes = ref<TaskType[]>([]);
const hourTypes = ref<HourType[]>([]);
const users = ref<any>([]);

const user = ref<any>(null);
// params:
const params: any = reactive({
  tms_shortinfo: null,
  tms_description: null,
  hours: null,
  minutes: null,
  taskType: null,
  hourType: null,
  hours_approved: null,
  minutes_approved: null,
  hours_billed: null,
  minutes_billed: null,
});

function checkDescriptionLength() {
  if (params.tms_description && params.tms_description.length) {
    hoursStore.setDescriptionFilled();
  } else {
    hoursStore.clearDescriptionFilled();
  }
}
async function getTaskTypes() {
  try {
    const params: any = {
      cmp_id: companyStore.activeCompany?.cmp_id,
      view_all: true,
    };
    const res = await api.getTaskTypes(params);
    taskTypes.value = res.data.data;
  } catch (error) {
    console.log(error);
  }
}
getTaskTypes();

function setTaskType(taskType: TaskType) {
  params.taskType = taskType;
}

// watchEffect(() => {
//   const handleKeyDown = (event) => {
//     if (event.ctrlKey && event.key === "Enter") {
//       saveHoursShortcutCtrlEnter();
//     }
//   };
//   window.addEventListener("keydown", handleKeyDown);
//   return () => {
//     // window.removeEventListener("keydown", handleKeyDown);
//   };
// });
// function saveHoursShortcutCtrlEnter() {
//   saveHours();
// }
async function getUsers() {
  try {
    const params: any = {
      cmp_id: companyStore.getActiveCompany?.cmp_id,
    };
    const res = await api.searchUsers(params);
    users.value = res.data.data;
  } catch (error) {
    console.log(error);
  }
}
getUsers();

function setUser(u: any) {
  user.value = u;
}

function setOneHour() {
  params.hours = 1;
  params.minutes = 0;
}

onMounted(() => {
  setOneHour();
});

onUnmounted(() => {
  hoursStore.clearDescriptionFilled();
});

async function gethourTypes() {
  try {
    const params: any = {
      cmp_id: companyStore.activeCompany?.cmp_id,
    };
    const res = await api.getHourTypes(params);
    hourTypes.value = res.data.data;
    const regular: HourType = res.data.data.find(
      (el: HourType) => el.tst_name === "Regular"
    );
    if (regular) {
      setHourType(regular);
    }
  } catch (error) {
    console.log(error);
  }
}
gethourTypes();

function setHourType(hourType: HourType) {
  params.hourType = hourType;
}

async function saveHours() {
  if (!params.hours && !params.minutes) {
    openCustomToast("The 'Time' field is required", "error");
    return;
  }
  if (!params.tms_shortinfo || params.tms_shortinfo.trim()?.length <= 0) {
    openCustomToast("keywords / short field is required", "error");
    return;
  }
  if (params.tms_shortinfo && params.tms_shortinfo?.length > 255) {
    openCustomToast("keywords / short info is too big", "error");
    return;
  }
  if (params.taskType && params.hourType) {
    try {
      params.ttp_id = params.taskType.ttp_id;
      params.tst_id = params.hourType.tst_id;
      params.tsk_id = props.options.data.tsk_id;
      if (user.value) {
        params.usr_id = user.value.usr_id;
      }
      const cleanedParams = {};
      Object.keys(params).forEach((key) => {
        if (params[key] !== null) {
          cleanedParams[key] = params[key];
        }
      });
      await api.addHours(cleanedParams);
      if (props.options.update) {
        props.options.update();
      }
      modalStore.setModalData(null);
      hoursStore.clearDescriptionFilled();
      openCustomToast("You have successfully added hours.", "success");
    } catch (error: any) {
      if (error.response.data.message === "task-is-locked") {
        openCustomToast("The task is locked.", "error");
      } else {
        openCustomToast("An error occurred while adding the hours.", "error");
      }
    }
  } else {
    openCustomToast(
      "The fields 'Task type' and 'Hour type' are required.",
      "error"
    );
  }
}
function close() {
  modalStore.setModalData(null);
}
</script>
<style lang="scss" scoped>
.confirmation {
  display: flex;
  justify-content: flex-end;
  .btn-wrapper {
    margin: 0 0 0 30px;
  }
}
.add-hours-wrapper {
  display: flex;
  flex-direction: column;
  .space {
    margin: 0 15px 0 15px;
  }
  .task-name {
    position: relative;
  }
  .task-name::after {
    content: "";
    position: absolute;
    bottom: 14px;
    left: 0;
    width: 56px;
    height: 2px;
    background: linear-gradient(
        to left,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0.5) 50%,
        rgba(0, 0, 0, 0) 100%
      ),
      var(--task-color);
    // margin: 0 0 0 7px;
  }

  .first-content {
    display: flex;
    // min-width: 1000px;
    justify-content: space-between;
    .input-wrapper {
      .types {
        .label {
          font-family: "Archivo";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 15px;
          color: #d0dcf3;
        }
      }
    }
    .time-central-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 40px 0 0;

      .time-buttons-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      .cc-desc {
        font-size: 12px;
        line-height: 13px;
        font-weight: 600;
        margin-bottom: 6px;
        color: var(--label-color);
      }
    }

    .time-central-content-new {
      display: flex;
      flex-direction: column;
      // color: #282D3D;
      align-items: center;
      // margin: 0 5px 0 0;

      .cc-desc {
        font-size: 12px;
        line-height: 13px;
        font-weight: 600;
        margin-bottom: 6px;
        color: var(--label-color);
        align-self: flex-start;
      }

      .time-buttons-wrapper {
        font-family: "Archivo";
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 16px;
        background: var(--background-input) !important;
        color: #555f76;
        display: flex;
        flex-direction: row;
        align-items: center;
        // border: 1px solid #282d3d;
        border: 1px solid var(--link-border-default-color);
        border-radius: 10px;
        padding: 0 10px 0 10px;
        height: 36px;
      }
    }
  }
  .section {
    margin: 0 0 33px 0;

    .input-wrapper {
      // margin: 0 7px 0 7px;

      h1 {
        font-family: Archivo;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 20px;
        color: #ecedf2;
        margin: 17px 0;
      }

      h1::first-letter {
        text-transform: capitalize;
      }
    }
  }

  .central-content.no-margin {
    margin-right: 0 !important;
  }

  .central-content {
    display: flex;
    margin: 0 0 20px;

    .central-content {
      min-height: 80px;
      min-width: 200px;
      .hour-types {
        width: 150px;
        height: 36px;
        margin: 0 14px 0 0;
      }
      .input-wrapper {
        min-width: 185px;
        .input-outer {
          background: var(--background-input) !important;
        }
        .types {
          min-width: 183px;
        }
        .assigned-to {
          width: 150px;
          height: 36px;
        }
      }
    }

    .time-buttons-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: -16px;
    }
  }
}

.btn-wrapper {
  display: flex;
  justify-content: flex-end;
}
@media screen and (width <= 768px) {
  .add-hours-wrapper {
    max-height: calc(100vh - 150px);
    overflow: auto;
    margin-bottom: 20px;
  }
  .first-content {
    flex-wrap: wrap;
    .space {
      margin-bottom: 20px;
      margin-right: 5px;
    }
    .time-central-content-new {
      margin-left: 5px;
      margin-right: 5px;
    }
  }
  .central-content,
  .task-name {
    margin-bottom: 0 !important;
  }
}
</style>
