<template>
  <div class="task-details-wrapper">
    <div class="task-details-wrapper-left">
      <table>
        <thead>
          <tr>
            <th>PRY.</th>
            <th>DUE DATE</th>
            <th>PROGRESS / HOURS</th>
            <th>STATUS</th>
            <!-- <th style="text-align: center">HOURS</th> -->
            <th>ID</th>
            <th>CREATED</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div class="table-cell priority">
                <!-- <span>{{ `${data.progress}%` }}</span>
                <progress
                  :class="{ full: data.progress > 100 }"
                  :value="data.progress"
                  max="100"
                  min="0"
                ></progress> -->
                <priority-task
                  v-if="data.tsk_priority"
                  :data="data.tsk_priority"
                />
              </div>
            </td>
            <td>
              <div class="table-cell date">
                <template v-if="data.tsk_due">
                  <span class="day">{{
                    `${dayjs.utc(data.tsk_due).local().format("DD.MM.YYYY")}.`
                  }}</span>
                  <span class="time">
                    {{ `${dayjs.utc(data.tsk_due).local().format("HH:mm")}` }}
                  </span>
                </template>
                <span v-else></span>
              </div>
            </td>
            <td>
              <div class="table-cell progress">
                <!-- <span>{{ `${data.progress}%` }}</span>
                <progress
                  :class="{ full: data.progress > 100 }"
                  :value="data.progress"
                  max="100"
                  min="0"
                ></progress> -->
                <progress-task
                  :data="data.progress"
                  :hours="hours"
                  :showHours="true"
                />
                <!-- <progress-task :data="data.progress" :hours="hours" /> -->
              </div>
            </td>
            <td>
              <div class="table-cell">
                <basic-dropdown
                  v-if="
                    (props.details.editable &&
                      props.details.deletable &&
                      !details.tsk_locked) ||
                    (isSetStatus && !details.tsk_locked)
                  "
                  :list="statuses"
                  :selected="data.status"
                  :labelKey="'sta_name'"
                  @selected="setStatus"
                />
                <span v-else>
                  {{ data.status?.sta_name }}
                </span>
              </div>
            </td>
            <!-- <td>
              <div
                v-if="hours && (hours.spent || hours.estimate)"
                class="table-cell hours"
                :class="{
                  red: hours?.estimate < hours?.spent && hours?.estimate > 0,
                }"
              >
                <span class="spent"> {{ hours?.spent }} </span
                >{{ hours?.estimate ? "/" + hours.estimate : "" }}
              </div>
              <div v-else class="table-cell"></div>
            </td> -->
            <td>
              <div class="table-cell id">
                <span>{{ data.tsk_id }}</span>
              </div>
            </td>
            <td>
              <div class="table-cell date">
                <template v-if="data.tsk_created">
                  <span class="day">{{
                    `${dayjs
                      .utc(data.tsk_created)
                      .local()
                      .format("DD.MM.YYYY")}.`
                  }}</span>
                  <span class="time">
                    {{
                      `${dayjs.utc(data.tsk_created).local().format("HH:mm")}`
                    }}
                  </span>
                </template>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="scrollbar content">
        <div class="description-wrapper">
          <div class="title">
            DESCRIPTION
            <img
              v-if="
                props.details.editable &&
                props.details.deletable &&
                !details.tsk_locked
              "
              src="@/assets/icons/edit.png"
              alt="edit image"
              @click="openEditTaskModal"
            />
          </div>
          <pre class="description">{{ data.tsk_description }}</pre>
        </div>
      </div>
    </div>
    <div class="task-details-wrapper-right">
      <span class="title">Created by</span>
      <created-by
        v-if="data.owner"
        :user="data.owner"
        style="margin-bottom: 49px"
      />
      <div class="assigned-section-wrapper">
        <div class="title">
          ASSIGNED
          <img
            v-if="
              props.details.editable &&
              props.details.deletable &&
              !details.tsk_locked
            "
            src="@/assets/icons/edit.png"
            alt="edit image"
            @click="openEditTaskModal"
          />
        </div>
        <div class="scrollbar assigned-wrapper">
          <div v-for="ass in data.assigned" :key="ass.usr_id" class="assigned">
            <created-by v-if="ass.usr_id" :user="ass" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <show-flile v-if="false" :details="details" @update="emit('update')" />
</template>
<script setup lang="ts">
import api from "@/api";
import { ModalStore } from "@/store/modalStore";
import { UserStore } from "@/store/userStore";
// import fileData from "../elements/files/fileData.vue"
import dayjs from "dayjs";

const data: any = reactive({
  tsk_due: null,
  tsk_created: null,
  progress: 0,
  status: null,
  hours: null,
  owner: null,
  tsk_description: null,
  tsk_priority: null,
  assigned: [],
  tsk_id: 0,
});
// const months = [
//   "JAN",
//   "FEB",
//   "MAR",
//   "APR",
//   "MAJ",
//   "JUN",
//   "JUL",
//   "AVG",
//   "SEP",
//   "OCT",
//   "NOV",
//   "DEC",
// ];
const props = defineProps(["details", "statuses", "hours"]);
const emit = defineEmits(["update"]);
const modalStore = ModalStore();
const userStore = UserStore();

async function setStatus(status: any) {
  try {
    const params: any = {
      sta_id: status.sta_id,
    };
    await api.editTask(params, props.details.tsk_id);
  } catch (error) {
    console.log(error);
  } finally {
    emit("update");
  }
}

function openEditTaskModal() {
  const d = JSON.parse(JSON.stringify(props.details));
  d.assigned_users = d.assigned;
  const data: any = {
    component: "editTaskModal",
    title: "Edit task",
    options: {
      data: d,
      save: () => {
        emit("update");
      },
    },
  };
  modalStore.setModalData(data);
}

function setupData() {
  data.tsk_due = props.details.tsk_due;
  data.progress = props.details.progress;
  data.status = props.details.status;
  data.tsk_created = props.details.tsk_created;
  data.owner = props.details.usr_created;
  data.tsk_description = props.details.tsk_description;
  data.assigned = props.details.assigned;
  data.tsk_priority = props.details.tsk_priority;
  data.tsk_id = props.details.tsk_id;
  checkSetStatus(props.details.assigned, props.details.usr_created);
}

const isSetStatus = ref(false);
function checkSetStatus(data: any, owner: any) {
  isSetStatus.value = false;
  let id = userStore.getUser?.id;
  // if(owner && owner.usr_id === id) {
  //   isSetStatus.value = true
  //   return
  // }
  if (data) {
    data.forEach((el: any) => {
      if (el.usr_id === id) {
        isSetStatus.value = true;
      }
    });
  }
}

watch(
  () => props.details,
  () => setupData()
);
onMounted(() => {
  setupData();
});
</script>
<style lang="scss" scoped>
.task-details-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  // min-height: 50%!important; // remove this if you want those files to follow the description task
  height: 55% !important;
  max-height: 530px !important;

  .task-details-wrapper-left {
    flex-grow: 1;
    padding-top: 22px;
    padding-top: 38px;
    height: 100%;
    // overflow-x: hidden;
    // overflow-y: auto;
  }

  .task-details-wrapper-right {
    min-width: 206px;
    padding-left: 10px;
    padding-top: 38px;
    height: 100%;

    .assigned-section-wrapper {
      min-width: 206px;
      display: flex;
      flex-direction: column;
      min-width: 150px;
      .assigned-wrapper {
        //  min-height: calc(100% - 102px) !important;
        height: 350px;
        overflow-x: hidden;
        overflow-y: auto;
      }
      .assigned {
        margin-top: 20px;
      }

      .assigned:first-child {
        margin-top: 10px;
      }
    }
  }

  table {
    width: 100%;
    overflow: auto;

    th {
      text-align: left;
      font-weight: 700;
      font-size: 11px;
      line-height: 12px;
      text-transform: uppercase;
      color: var(--label-color);
      padding-right: 15px;
      box-sizing: border-box;
      min-height: 28px;
      cursor: pointer;
      padding-bottom: 8px;
      white-space: nowrap;
      margin-bottom: 10px;
    }

    .table-cell {
      padding-right: 15px;
    }
    .table-cell.id {
      span {
        font-style: normal;
        font-weight: 700;
        font-size: 13px;
        line-height: 14px;
        color: var(--label-color);
      }
    }

    .table-cell.hours {
      white-space: nowrap;
      text-align: center;

      .spent {
        font-weight: bold;
      }
    }

    .table-cell.hours.red {
      color: #dd3554;
    }

    th:first-child,
    td:first-child .table-cell {
      padding-left: 0;
    }

    th:last-child,
    td:last-child .table-cell {
      padding-right: 0;
    }

    .table-cell.date {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      .day {
        // font-weight: 700;
        // font-size: 15px;
        // line-height: 16px;
        font-weight: 600;
        font-size: 13px;
        line-height: 15px;
        white-space: nowrap;
      }

      .time {
        // font-weight: 400;
        // font-size: 11px;
        // line-height: 15px;
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 15px;
        white-space: nowrap;
      }
    }
    .table-cell.priority {
      min-width: 90px;
    }
    .table-cell.progress {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      height: 100%;

      span {
        font-weight: 700;
        font-size: 11px;
        line-height: 12px;
        color: #7b7e8f;
        width: 100%;
        margin-bottom: 5px;
      }

      progress {
        height: 9px;
        width: 75px;
        appearance: none;
        appearance: none;
        border-radius: 5px;
        overflow: hidden;
        border: none;
        box-sizing: border-box;
      }

      progress::-webkit-progress-value {
        transition: var(--vt-c-transition-duration);
        background-color: var(--vt-c-active);
        border-radius: 5px;
      }

      progress.full::-webkit-progress-value {
        background-color: var(--vt-c-error);
      }

      progress::-webkit-progress-bar {
        background: rgb(236 237 242 / 10%);
        border-radius: 5px;
      }
    }
  }

  .content {
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    height: calc(100% - 102px) !important;
    overflow-x: initial;
    overflow-y: auto;

    .description-wrapper {
      flex-grow: 1;

      .description {
        // word-break: break-all;
        max-width: 550px;
        white-space: pre-wrap;
      }
    }
  }
}
.title {
  font-weight: 700;
  font-size: 11px;
  line-height: 12px;
  text-transform: uppercase;
  color: var(--label-color);
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;

  img {
    margin-left: 10px;
    cursor: pointer;
  }
}

// second part
</style>
