<template>
  <div class="wrappSelectColor">
    <div class="label">Colors</div>

    <div class="wrappSelectColorTask">
      <div
        v-for="(color, index) in colorArr"
        :key="index"
        :style="{
          background: `linear-gradient(180deg, ${color.color} 0%, ${color.style} 100%)`,
        }"
        class="currentColor"
        :class="{ select: color.color.toLowerCase() === selectedColor }"
        @click="setColor(color.color)"
      ></div>
      <div
        v-click-outside="
          () => {
            showColor = false;
          }
        "
        class="round"
        :class="{ profilePicker: profile }"
      >
        <color-picker
          v-if="showColor"
          v-model:pureColor="pureColor"
          v-model:gradientColor="gradientColor"
          format="hex"
          :disableAlpha="true"
          :isWidget="true"
          :pickerType="profile ? 'chrome' : 'fk'"
          :disableHistory="profile ? true : false"
          :class="{ select: pureColor === selectedColor }"
        />
        <div
          :style="{
            background:
              pureColor === defaultColor
                ? pureColor
                : `linear-gradient(180deg, ${pureColor} 0%, rgba(138, 134, 138, 0.061) 100%)`,
          }"
          class="currentColor"
          :class="{
            select: pureColor.toLowerCase() === selectedColor?.toLowerCase(),
          }"
          @click="showColor = !showColor"
        >
          <!--new -> rgba(138, 134, 138, 0.061) ; old ->  rgba(221, 53, 204, 0.2)  -->
          <img src="../../assets/icons/pen.png" />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref } from "vue";
import { type ColorInputWithoutInstance } from "tinycolor2";

const emit = defineEmits(["color"]);
const props = defineProps(["old", "numberOfcolor", "profile"]);

const showColor = ref(false);
const colorArr = ref([
  { color: "#DD35CC", style: "rgba(221, 53, 204, 0.2)" },
  { color: "#FBB209", style: "rgba(251, 178, 9, 0.2)" },
  { color: "#02A657", style: "rgba(2, 166, 87, 0.2)" },
  { color: "#2C75F4", style: "rgba(44, 117, 244, 0.2)" },
  { color: "#FF0000", style: "rgba(255, 0, 0, 0.2)" },
  { color: "#602BB6", style: "rgba(96, 43, 182, 0.2)" },
  { color: "#00838B", style: "rgba(0, 131, 139, 0.2)" },
  { color: "#454FA9", style: "rgba(69, 79, 169, 0.2)" },
]);
const defaultColor = "#658CD8";
const pureColor = ref<ColorInputWithoutInstance>(
  props.old ? props.old : defaultColor
);
const selectedColor = ref<ColorInputWithoutInstance | null>(
  props.old ? props.old : null
);
const gradientColor = ref(
  "linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 100%)"
);
function setColor(color: string) {
  selectedColor.value = color.toLowerCase();
  emit("color", selectedColor.value);
}
function reduceColor() {
  if (props.numberOfcolor) {
    colorArr.value = colorArr.value.slice(0, props.numberOfcolor);
  }
}
reduceColor();
watch(
  () => pureColor.value,
  () => {
    if (pureColor.value !== defaultColor) {
      selectedColor.value = pureColor.value;
      // if(colorArr.value.includes(selectedColor.value.toUpperCase())) {
      //     pureColor.value = defaultColor;
      //      console.log(defaultColor);
      // }
      emit("color", selectedColor.value);
    }
  }
);
</script>
<style lang="scss">
.profilePicker {
  .vc-saturation,
  .vc-compact,
  .vc-color-input {
    display: none !important;
  }
  .vc-current-color.vc-transparent,
  .color-cube {
    width: 100% !important;
    height: 20px !important;
  }
  .vc-current-color.vc-transparent {
    margin-left: 10px !important;
    margin-right: 0px !important;
  }
}
.vc-color-wrap.transparent {
  background-image: none !important;
  max-width: 100%;
}

.wrappSelectColor {
  font-size: 12px;
  line-height: 13px;
  font-weight: 600;

  .label {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 13px;
    color: var(--label-color);
    // margin-bottom: 3px;// po dizajnu
    // margin-bottom: 11px;// po dizajnu
    margin-bottom: 11px; // in line
  }
}
.round {
  position: relative;
  width: 22px !important;
  height: 22px !important;
  img {
    position: absolute;
    top: 5px;
    left: 5px;
  }
}

.wrappSelectColorTask {
  display: flex;

  .current-color,
  .currentColor {
    width: 22px !important;
    height: 22px !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 50%;
    margin-right: 10px;
    cursor: pointer;
  }

  .currentColor.select {
    border: var(--border-selected-color);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
}

.vc-colorpicker {
  background-color: $background-colorpicker !important;
  position: absolute !important;
  bottom: 30px;
  right: -130px;
  border-radius: 5px !important;
  overflow: hidden;
  padding-top: 10px;

  .vc-colorpicker--container {
    .vc-fk-colorPicker {
      background-color: $background-colorpicker !important;

      .vc-fk-colorPicker__inner {
        .vc-display {
          .vc-color-input {
          }
          // .vc-input-toggle,
          // .vc-input-toggle::before,
          // .vc-input-toggle::after {
          //   display: none !important;
          // }

          .vc-alpha-input {
            display: none !important;

            .vc-alpha-input__inner {
              color: black;
            }
          }
        }
      }
    }
  }
}
.vc-input-toggle,
.vc-input-toggle::before,
.vc-input-toggle::after {
  display: none !important;
}
.vc-saturation {
  // margin-bottom: 0 !important;
}
.vc-colorpicker--container,
.vc-chrome-colorPicker-body,
.vc-chrome-colorPicker {
  background-color: $background-colorpicker !important;
}
.chrome-color-wrap {
  display: none;
}
</style>
